<template>
  <v-container class="sign-up-complete">
    <v-row no-gutters>
      <header class="col-12 content-header justify-center text-left">
        <h1 class="content-title">
          <template v-if="$vuetify.breakpoint.smAndUp">
            Almost there...
          </template>
        </h1>
      </header>
    </v-row>
    <v-row class="content-body justify-center align-center">
      <v-col class="col-12 py-0">
        <p class="content-subtitle">
          Before continuing, we need to verify your email address.<br>
          Please check your inbox for a confirmation link.
        </p>
        <p v-if="!isMessageSent">
          Didn't receive an email? <a
            class="text-decoration-underline"
            @click="sendEmailAgain"
          >Resend</a>
        </p>
        <p v-else>
          Message sent. Please check your inbox.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'


export default {
  name: 'SignUpComplete',
  data() {
    return {
      isMessageSent: false
    }
  },
  computed: {
    // ...mapGetters('authentication', [''])
  },
  created() {
    this.$gtag.event('conversion', { send_to: 'AW-10995571789/3DdFCNTIj4sYEM24jPso' })
  },
  methods: {
    ...mapActions('authentication', ['resendEmail', 'sendEmailVerification']),
    async sendEmailAgain() {
      // Hide the link before the email is sent, assuming it goes through since we don't have error handling otherwise
      this.isMessageSent = true
      await this.sendEmailVerification()
    }
  },
  head: {
    title: {
      inner: 'Sign up complete'
    }
  },
}
</script>
<style scoped>

  header {
    text-align: left;
  }
  .content-title {
    margin-top: 2rem;
  }

  .content-body {
    min-height: 100%;
  }


</style>